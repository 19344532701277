<template>
  <drag-modal :visible="visible"
              :loading="loading"
              width="4.5rem"
              @cancel="() => (visible = false)">
    <div class="flex-row justify-between align-center"
         slot="title">
      <div class="flex-row flex-1">
        <span class="font-bold trans-o-left"
              style="transform: scale(0.931944); font-size: 11.1833px">{{ title }}{{ queryParam.Name }}</span>
      </div>
      <div>
        <span style="
            padding-right: 13.9792px;
            padding-left: 13.9792px;
            display: inline-block;
            transform: scale(0.815451);
            font-size: 9.78542px;
          ">
          今日报警 <span class="font-bold">{{ alarmCount }}</span> 次</span>
      </div>
    </div>
    <div>
      <div class="flex-column center"
           style="transform: scale(0.815451); font-size: 9.78542px">
        <span v-if="data.DeviceStatus == 2"
              style="color: green">(在线)</span><span v-else
              style="color: red">(离线)</span>
        <div style="float: right">采集时间：{{ data.RecordTime }}</div>
      </div>
    </div>
    <div>
      <div class="align-center"
           style="padding-left: 86px">
        <Echart :options="options"
                id="aqi"
                width="1.78rem"
                height="1.78rem"></Echart>
      </div>
      <div class="flex-row">
        <div class="grid-content">
          <div style="margin-bottom: 3.94074px">
            <img src="../../assets/image/g_qiwen.png"
                 style="
                transform: translateX(0.02rem);
                width: 0.25rem;
                height: 0.49rem;
              " />
          </div>
          <div>{{ data.Temperature }} ℃</div>
        </div>
        <div class="grid-content">
          <div style="margin-bottom: 3.94074px">
            <img src="../../assets/image/g_wendu.png"
                 style="width: 0.45rem; height: 0.48rem" />
          </div>
          <div>{{ data.Humidity }} %</div>
        </div>
        <div class="grid-content">
          <div style="margin-bottom: 3.94074px">
            <img src="../../assets/image/g_fengsu.png"
                 style="width: 0.48rem; height: 0.48rem" />
          </div>
          <div>
            {{ data.WindSpeed }} m/s <span>{{ data.DirectionText }}</span>
          </div>
        </div>
      </div>
      <div class="contaminant">
        <div class="flex-row">
          <div class="flex-fix">
            <span style="display: inline-block">污染物</span>
            <span style="display: inline-block">（μg/m³）</span>
          </div>
          <div class="flex-1">
            <span style="
                height: 1px;
                background: rgba(255, 255, 255, 0.3);
                width: 100%;
                display: inline-block;
              "></span>
          </div>
        </div>
        <div class="flex-row">
          <div class="grid-content">
            <span style="
                width: 40px;
                height: 1.68889px;
                background: rgb(62, 225, 154);
                display: inline-block;
              "></span>
            <div style="margin: 2.81481px 0px">PM2.5</div>
            <div class="font-bold"
                 style="margin-bottom: 3.94074px">
              {{ data.PM25 }}
            </div>
            <div v-if="data.PM25 <= 0">--</div>
            <div v-else-if="data.PM25 < 35">优</div>
            <div v-else-if="data.PM25 < 75">良</div>
            <div v-else-if="data.PM25 < 115">轻度污染</div>
            <div v-else-if="data.PM25 < 150">中度污染</div>
            <div v-else-if="data.PM25 < 250">重度污染</div>
            <div v-else>严重污染</div>
          </div>
          <div class="grid-content">
            <span class="line-item-blue"
                  style="
                width: 40px;
                height: 1.68889px;
                background: rgb(62, 225, 154);
                display: inline-block;
              "></span>
            <div style="margin: 2.81481px 0px">PM10</div>
            <div class="font-bold"
                 style="margin-bottom: 3.94074px">
              {{ data.PM10 }}
            </div>
            <div v-if="data.PM10 <= 0">--</div>
            <div v-else-if="data.PM10 < 50">优</div>
            <div v-else-if="data.PM10 < 100">良</div>
            <div v-else-if="data.PM10 < 150">轻度污染</div>
            <div v-else-if="data.PM10 < 200">中度污染</div>
            <div v-else-if="data.PM10 < 250">重度污染</div>
            <div v-else>严重污染</div>
          </div>
          <div class="grid-content">
            <span class="line-item-blue"
                  style="
                width: 40px;
                height: 1.68889px;
                background: rgb(62, 225, 154);
                display: inline-block;
              "></span>
            <div style="margin: 2.81481px 0px">TSP</div>
            <div class="font-bold"
                 style="margin-bottom: 3.94074px">
              {{ data.TSP }}
            </div>
            <div v-if="data.TSP <= 0">--</div>
            <div v-else-if="data.TSP < 50">优</div>
            <div v-else-if="data.TSP < 100">良</div>
            <div v-else-if="data.TSP < 150">轻度污染</div>
            <div v-else-if="data.TSP < 200">中度污染</div>
            <div v-else-if="data.TSP < 250">重度污染</div>
            <div v-else>严重污染</div>
          </div>
        </div>
      </div>
      <div class="noise"
           style="margin-top: 5.62963px">
        <div class="flex-row">
          <div class="flex-fix">
            <span style="display: inline-block">噪音</span>
            <span style="display: inline-block">（db）</span>
          </div>
          <div class="flex-1">
            <span style="
                height: 1px;
                background: rgba(255, 255, 255, 0.3);
                width: 100%;
                display: inline-block;
              "></span>
          </div>
        </div>
        <div class="flex-row center"
             style="margin-top: 11.2593px">
          <div class="grid-content">
            <span class="line-item-orange"
                  style="
                width: 40px;
                height: 1.68889px;
                background: rgb(62, 225, 154);
                display: inline-block;
              "></span>
            <div style="margin: 2.81481px 0px">{{ data.Noise }}</div>
            <div v-if="data.Noise <= 0">--</div>
            <div v-else-if="data.Noise < 50">好</div>
            <div v-else-if="data.Noise < 55">较好</div>
            <div v-else-if="data.Noise < 60">轻度污染</div>
            <div v-else-if="data.Noise < 65">中度污染</div>
            <div v-else>重度污染</div>
          </div>
        </div>
      </div>
    </div>
  </drag-modal>
</template>
<script>
import DragModal from "@/components/DragModal";
import Echart from "@/common/echart";
export default {
  data () {
    return {
      loading: true,
      visible: false,
      title: "",
      alarmCount: 0,
      data: {},
      options: {},
      queryParam: {},
    };
  },
  components: {
    DragModal,
    Echart,
  },
  watch: {
    data (newData) {
      let upPressure = newData.Atmospheric;
      let name = "AQI";
      this.options = {
        tooltip: {
          formatter: " {c}ug/m³",
        },
        series: [
          {
            name: name,
            type: "gauge",
            //center: ["50%", "45%"], // 默认全局居中
            min: 0,
            max: 500,
            detail: {
              formatter: "{value}",
              offsetCenter: [0, 0],
              color: "#fff",
            },
            title: {
              offsetCenter: [0, "68%"], // x, y，单位px
              fontWeight: "bolder",
              fontSize: 15,
              fontStyle: "italic",
              color: "#fff",
              shadowColor: "#fff", //默认透明
              shadowBlur: 10,
            },
            data: [{ value: upPressure, name: name }],
            clockwise: true,
            radius: "90%",
            axisLabel: {
              // 刻度标签。
              show: false,
              distance: -5,
              color: "white",
              fontSize: 10,
              formatter: "{value}",
            },
            splitLine: { show: false },
            axisLine: {
              // 仪表盘轴线(轮廓线)相关配置。
              show: true,
              lineStyle: {
                // 仪表盘轴线样式。
                opacity: 1,
                width: 10,
                shadowBlur: 50,
                color: [
                  [upPressure / 500, "green"],
                  [1, "gray"],
                ],
              },
            },
            pointer: {
              // 仪表盘指针。
              show: true,
              length: "70%",
              width: 2,
            },
          },
        ],
      };
    },
  },
  methods: {
    openForm (device) {
      this.title = device.DeviceMethodStr;
      this.queryParam = {
        ProId: device.ProId,
        Name: device.Name,
        DeviceId: device.Id,
      };
      this.loading = false;
      this.visible = true;
      this.init();
    },
    init () {
      this.loading = true;
      this.$http
        .post("/DeviceManage/DeviceEnv/GetEnvLastRecord", {
          ProId: this.queryParam.ProId,
          DeviceId: this.queryParam.DeviceId,
          Code: this.queryParam.Code
        })
        .then((resJson) => {
          this.loading = false;
          this.data = resJson.Data;
        });
    },
  },
};
</script>
<style scoped>
.align-center {
  align-items: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  text-align: center;
}
.device-list li {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.grid-content {
  text-align: center;
  width: 33.33%;
}
</style>